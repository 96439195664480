import React from "react";
import { Table, Steps, Divider, Button, Radio, Input, InputNumber, message, Row, Col, Spin } from "antd";
import Console from "../components/console";
import Seo from "../components/seo";

import Api from "../components/api";
import "../styles/transaction.css";

const {Step} = Steps;

const columns = [
    {
        title: '',
        dataIndex: 'id',
        key: 'radio',
        render: (text) => <Radio value={text} />
    },
    {
        title: 'N° Compte',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: 'Type de compte',
        dataIndex: 'type',
        key: 'type'
    },
    {
        title: 'Solde',
        dataIndex: 'amount',
        key: 'amount'
    }
];

class Transaction extends React.Component {

    state = {
        current: 0,
        data: null,
        source: null,
        destination: null,
        amount: 0,
        preview: null,
        comment: ""
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        this.setState({
            current: 0,
            data: null,
            source: null,
            destination: null,
            amount: 0
        });
        Api("accounts_all", {})
                .then(data => {
                    this.setState({data : data.accounts});
                });
    }

    next() {
        const current = this.state.current + 1;
        if (current === 3) {
            this.onPreviewTransaction();
        }
        this.setState({current});
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({current});
    }

    onChangeSource = e => {
        const source = e.target.value;
        this.setState({source});
    }

    onChangeDestination = e => {
        const destination = e.target.value;
        this.setState({destination});
    }

    onChangeAmount = amount => {
        this.setState({amount});
    }

    onChangeComment = e => {
        this.setState({comment: e.target.value});
    }

    onPreviewTransaction = () => {
        const {source, destination, amount, comment} = this.state;
        this.setState({preview: null});
        Api("transaction_preview", {
            "account_source_id": source,
            "account_destination_id": destination,
            "amount": amount,
            "comment": comment
        }).then(preview => {
            this.setState({preview});
        });
    }

    applyTransaction = () => {
        const {source, destination, amount, comment} = this.state;
        Api("transaction_push", {
            "account_source_id": source,
            "account_destination_id": destination,
            "amount": amount,
            "comment": comment
        }).then(response => {
            if (response.result) {
                if (!response.message) {
                    message.success('La transaction a été réalisée avec succès !');
                } else {
                    message.success(response.message);
                }
                this.load();
            } else {
                message.error(response.message);
            }
        });
    }

    render() {
        const {current, data, source, destination, amount, preview, comment} = this.state;
        const steps = [
            {
                title: 'Source',
                content: <Radio.Group onChange={this.onChangeSource} value={source} style={{width: "100%"}}>
                    <Table dataSource={data} loading={!data} columns={columns} />
                </Radio.Group>
            }, {
                title: 'Destination',
                content: <Radio.Group onChange={this.onChangeDestination} value={destination} style={{width: "100%"}}>
                    <Table dataSource={data} loading={!data} columns={columns} />
                </Radio.Group>
            },
            {
                title: 'Détails de la transaction',
                content: <div>
                    <Row gutter={30}>
                        <Col span={6}><b>Montant :</b></Col>
                        <Col span={6}>
                        <InputNumber
                            defaultValue={amount}
                            min={0}
                            formatter={value => `${value}€`}
                            parser={value => value.replace('€', '')}
                            onChange={this.onChangeAmount}
                            style={{width: "100%"}}
                            />
                        </Col>
                    </Row>
                    <Row gutter={30} style={{marginTop: "10px"}}>
                        <Col span={6}><b>Commentaire :</b></Col>
                        <Col span={6}>
                        <Input value={comment} onChange={this.onChangeComment} style={{width: "100%"}} />
                        </Col>
                    </Row>
                </div>
            },
            {
                title: 'Récapitulatif',
                content: <div>{ preview ? <React.Fragment>
                        <Row gutter={30}>
                            <Col span={7}></Col>
                            <Col span={7}><b>N° du compte</b></Col>
                            <Col span={7}><b>Solde après transaction</b></Col>
                            <Col span={3}><b>Montant</b></Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={7}><b>Compte source :</b></Col>
                            <Col span={7}>{source}</Col>
                            <Col span={7}>{preview.before.toLocaleString("fr", {minimumFractionDigits: 2})} €</Col>
                            <Col span={3}>-{amount.toLocaleString("fr", {minimumFractionDigits: 2})} €</Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={7}><b>Compte destination :</b></Col>
                            <Col span={7}>{destination}</Col>
                            <Col span={7}>{preview.after.toLocaleString("fr", {minimumFractionDigits: 2})} €</Col>
                            <Col span={3}>+{amount.toLocaleString("fr", {minimumFractionDigits: 2})} €</Col>
                        </Row>
                        { comment && <React.Fragment>
                            <Divider />
                            <Row gutter={30}><Col span={24}><b>Commentaire :</b></Col></Row>
                            <Row gutter={30}><Col span={24}>{comment}</Col></Row>
                        </React.Fragment>
                        }
                        { preview.message && <React.Fragment>
                            <Divider />
                            <Row gutter={30}><Col span={24}><b>Remarque :</b></Col></Row>
                            <Row gutter={30}><Col span={24}>{preview.message}</Col></Row>
                        </React.Fragment>
                        }
                    </React.Fragment> : <Spin />
                    }
                </div>
            }
        ];


        return (
                <Console className="transaction"
                         onChangeUser={this.load}
                         menu="transaction"
                         help={["accounts_all", "transaction_preview", "transaction_push", "transaction_validation_notification"]}>
                    <Seo title="Transaction" />
                    <h1>Transaction</h1>
                    <Steps current={current}>
                        {steps.map(item => (
                                        <Step key={item.title} title={item.title} />
                                        ))}
                    </Steps>
                    <div className="steps-content">{steps[current].content}</div>
                    <div className="steps-action">
                        {current < steps.length - 1 && (
                                    <Button type="primary" disabled={(current === 0 && !source)
                                                        || (current === 1 && (!destination || source === destination))
                                                                || (current === 2 && amount <= 0)} onClick={() => this.next()}>
                                        Suivant
                                    </Button>
                                    )}
                        {current === steps.length - 1 && (
                                    <Button type="primary" disabled={!preview || !preview.result} onClick={this.applyTransaction}>
                                        Finaliser la transaction
                                    </Button>
                                    )}
                        {current > 0 && (
                                    <Button style={{marginLeft: 8}} onClick={() => this.prev()}>
                                        Précédent
                                    </Button>
                                    )}
                    </div>
                </Console>
                );
    }
}
;

export default Transaction;
